// FontAwesome Pro
import "./node_modules/@fortawesome/fontawesome-pro/css/all.min.css";
import "./src/css/select2.min.css";
import "./src/plugins/aggrid/ag-grid.css";
import "./src/plugins/aggrid/ag-theme-balham-dark.css";
import "./src/plugins/aggrid/ag-grid-tms.css";
import "./node_modules/bootstrap-datetime-picker/css/bootstrap-datetimepicker.min.css";
import "./src/plugins/datepicker/daterangepicker.css";

// time picker
import "./src/plugins/timepicker/timepicker.css";

// dropzone
import "./src/plugins/dropzone/dropzone.css";

// glider
import "./src/plugins/glider/swiper.min.css";

// NPM packages
import jQuery from "jquery";
import "bootstrap";
import "bootstrap-datetime-picker";
import axios from "axios";
import moment from "moment";
import daterangepicker from "daterangepicker";
import tailSelect from "tail.select";
import jstates from "jstates";

// Add modules to window object
window.$ = jQuery;
window.jQuery = jQuery;
window.axios = axios;
window.moment = moment;
window.daterangepicker = daterangepicker;
window.tailSelect = tailSelect;
window.jstates = jstates;

// Include any override scripts
require("./src/javascript/overrides");

// Include plugins
require("./src/plugins/select2.full.min.js");
require("./src/plugins/timepicker/timepicker.js");
window.Swiper = require("./src/plugins/glider/swiper.min.js");
