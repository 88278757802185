import { clearForm, insertValue } from "./modules/forms";

// This file is for any override needed
const toggleNavCollapse = () => {
  const pageWidth = $(window).width();

  if (pageWidth >= 992) {
    $(".mobile-nav-items").removeClass("show");
  }
};

$(window).resize(() => {
  toggleNavCollapse();
});

toggleNavCollapse();

$("body").on("hidden.bs.modal", ".modal", function () {
  // Override buttons so they don't stay focused
  $(".btn").blur();
});

// Override for overlapping modals
$(".modal").on("hidden.bs.modal", function () {
  if ($(".modal.show").length) {
    $("body").addClass("modal-open");
  }
});

// Override for scroll bars to be reset
$("body").on("hidden.bs.modal", ".modal", function () {
  let divs = this.querySelectorAll("div");

  for (const div of divs) {
    div.scrollLeft = 0;
  }

  if (this.classList.contains("js-clear-modal")) {
    clearForm({ parent: this });
  }

  document
    .querySelectorAll(
      "body > .grid__dropdown-menu.generated:not(.js-not-removeable)"
    )
    .forEach((e) => e.remove());

  this.querySelectorAll("[data-default-value]").forEach((el) =>
    insertValue(el, el.dataset.defaultValue)
  );
});

$("body").on("hide.bs.modal", ".modal", function () {
  this.querySelectorAll("select.tail-select").forEach((el) => {
    tailSelect(el).enable(false);
  });
});
